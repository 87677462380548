body {
  overflow-x: hidden;
  /* background: linear-gradient(rgb(255, 255, 255), rgb(243, 244, 246), rgb(229, 231, 235)); */
  background-color: #f4f4f4;
}

.main-container {
  margin-left: 30px;
  margin-bottom: 10px;
}

label {
  @apply text-gray-600;
  @apply text-sm;
}
