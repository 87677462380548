.p-autocomplete {
  display: inline-flex;
  position: relative;
  z-index: 9999;
}

.p-autocomplete .p-inputtext {
  @apply border-gray-300 rounded-md;
}
.p-autocomplete .p-inputtext:enabled:hover {
}
.p-autocomplete .p-inputtext:enabled:focus {
  @apply focus:outline-none focus:ring-indigo-500 focus:border-indigo-500;
}
.p-autocomplete .p-inputtext.ng-dirty.ng-invalid {
  border-color: #f0a9a7;
}
.p-autocomplete .p-inputtext.p-inputtext-sm {
  /* font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem; */
}
.p-autocomplete .p-inputtext.p-inputtext-lg {
  /* font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem; */
}

.p-autocomplete-loader {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-autocomplete-dd .p-autocomplete-input {
  flex: 1 1 auto;
  width: 1%;
}

.p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0px;
}

.p-autocomplete .p-autocomplete-panel {
  min-width: 100%;
  top: 0;
  left: 0;
}

.p-autocomplete-panel {
  position: absolute;
  overflow: auto;
}

.p-autocomplete-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
  z-index: 9999;
}

.p-autocomplete-item {
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  z-index: 9999;
}

.p-autocomplete-multiple-container {
  margin: 0;
  padding: 0;
  list-style-type: none;
  cursor: text;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.p-autocomplete-token {
  cursor: default;
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
}

.p-autocomplete-token-icon {
  cursor: pointer;
}

.p-autocomplete-input-token {
  flex: 1 1 auto;
  display: inline-flex;
}

.p-autocomplete-input-token input {
  border: 0 none;
  outline: 0 none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  width: 100%;
}

.p-fluid .p-autocomplete {
  display: flex;
}

.p-fluid .p-autocomplete-dd .p-autocomplete-input {
  width: 1%;
}

.p-autocomplete-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  cursor: pointer;
}

.p-autocomplete-clearable {
  position: relative;
}
