.ql-container {
  height: auto;
  display: block;
}

.ql-editor {
  height: auto;
  max-height: 50vh;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}
