.menu-left {
  transition: width 0.2s;
  width: 30px;
  @apply fixed;
  @apply left-0;
  @apply top-0;
  @apply h-screen;
  @apply bg-[#171819];
  @apply shadow-lg;
  @apply overflow-y-auto;
  z-index: 2;
}
.menu-left-expanded {
  // @apply hidden;
}
.menu-left:hover .menu-left-expanded {
  display: block !important;
}
